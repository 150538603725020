<template>
    <div class="body-bg" style="-webkit-overflow-scrolling: touch">
          <iframe :src="pageUrl" frameborder="0" style="width: 100%;height:100%;overflow-y: scroll;"></iframe>
          <div class="return" @click="returnBtn" >
              返回<br/>
              首页
          </div>
    </div>
</template>
<style lang="less" scoped>
    .return {
        position: fixed;
        bottom: 80px;
        right: 40px;
        z-index: 1000;
        width: 80px;
        height: 80px;
        font-size: 24px;
        background: rgba(0,0,0,0.5);
        color: #fff;
        text-align: center;
        border-radius: 50%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
    }
</style>
<script>
export default {
    data() {
        return {
            pageUrl: ""
        }
    },
    methods: {
        returnBtn () {
            // this.$router.push('/');
            // console.log(11);
          history.back()
        }
    },
    created() {
         this.pageUrl = this.$route.params.url;
    }
}
</script>
